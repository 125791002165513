module.exports = [
  {
      title: 'Home',
      url: '/'
  },
  {
      title: 'Shop',
      url: '/shop'
  },
  {
      title: 'Raw Feeding',
      url: '/shop',
      submenu: {
          type: 'menu',
          menu: [
              { title: 'About', url: '/about' },
              { title: 'Blog', url: '/blog' },
              { title: 'Raw Feeding 101', url: '/raw-feeding-101' },
              { title: 'Cheatsheet', url: '/cheatsheet' }
          ],
      },
  },
  {
      title: 'Contact',
      url: '/contact'
  }
];




// module.exports = [
//     {
//         title: 'Home',
//         url: '/'
//     },
//     {
//         title: 'Megamenu',
//         url: '',
//         submenu: {
//             type: 'megamenu',
//             menu: {
//                 size: 'nl',
//                 columns: [
//                     {
//                         size: 6,
//                         links: [
//                             {
//                                 title: 'Power Tools',
//                                 url: '',
//                                 links: [
//                                     { title: 'Engravers', url: '' },
//                                     { title: 'Wrenches', url: '' },
//                                     { title: 'Wall Chaser', url: '' },
//                                     { title: 'Pneumatic Tools', url: '' },
//                                 ],
//                             },
//                             {
//                                 title: 'Machine Tools',
//                                 url: '',
//                                 links: [
//                                     { title: 'Thread Cutting', url: '' },
//                                     { title: 'Chip Blowers', url: '' },
//                                     { title: 'Sharpening Machines', url: '' },
//                                     { title: 'Pipe Cutters', url: '' },
//                                     { title: 'Slotting machines', url: '' },
//                                     { title: 'Lathes', url: '' },
//                                 ],
//                             },
//                         ],
//                     },
//                     {
//                         size: 6,
//                         links: [
//                             {
//                                 title: 'Hand Tools',
//                                 url: '',
//                                 links: [
//                                     { title: 'Screwdrivers', url: '' },
//                                     { title: 'Handsaws', url: '' },
//                                     { title: 'Knives', url: '' },
//                                     { title: 'Axes', url: '' },
//                                     { title: 'Multitools', url: '' },
//                                     { title: 'Paint Tools', url: '' },
//                                 ],
//                             },
//                             {
//                                 title: 'Garden Equipment',
//                                 url: '',
//                                 links: [
//                                     { title: 'Motor Pumps', url: '' },
//                                     { title: 'Chainsaws', url: '' },
//                                     { title: 'Electric Saws', url: '' },
//                                     { title: 'Brush Cutters', url: '' },
//                                 ],
//                             },
//                         ],
//                     },
//                 ],
//             },
//         },
//     },
//     {
//         title: 'Shop',
//         url: '/shop'
//     },
//     {
//         title: 'Blog',
//         url: '/blog'
//     },
//     {
//         title: 'Pages',
//         url: '/',
//         submenu: {
//             type: 'menu',
//             menu: [
//                 { title: 'About', url: '/about' },
//                 { title: 'Raw Feeding 101', url: '/raw-feeding-101' }
//             ],
//         },
//     }
//   ];