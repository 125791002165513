import React, { useContext, useState, useEffect } from 'react'

// import { connect } from 'react-redux';
import { Link } from 'gatsby'

import NavLinks from './NavLinks'
import Logo from '!svg-react-loader!../../images/svg/RawDogFood-Logo.svg?name=RawDogFood'

import CartIcon from './CartIcon'

function NavPanel(props) {
  return (
    <div className="nav-panel">
      <div className="nav-panel__container container">
        <div className="nav-panel__row">
          <div className="nav-panel__logo">
            <Link to="/">
              <Logo />
            </Link>
          </div>

          <div className="nav-panel__nav-links nav-links">
            <NavLinks />
          </div>

          <CartIcon name="nav-panel__indicators" />
        </div>
      </div>
    </div>
  )
}

export default NavPanel
