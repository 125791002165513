import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
// import styled from '@emotion/styled'
import NavPanel from './NavPanel'
// import { Flex, Box } from '@rebass/grid/emotion'

import StoreContext from '../../context/StoreContext'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

const Header = () => {
  const context = useContext(StoreContext)
  const { checkout } = context
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout])

  // <Link to='/basket'>
  // 	{quantity !== 0 &&
  // 		<p>
  // 			{quantity}
  // 		</p>
  // 	}
  // 	Cart 🛍
  // </Link>

  return (
    <div className="site-header">
      <div className="site-header__nav-panel">
        <NavPanel layout="compact" />
      </div>
    </div>
  )
}

export default Header
