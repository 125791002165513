// react
import React from 'react'

// application
import FooterContacts from './FooterContacts'
import FooterLinks from './FooterLinks'
import FooterNewsletter from './FooterNewsletter'

import LogoSmallSvg from '!svg-react-loader!../../images/svg/RawDogFood-Badge.svg'

export default function Footer() {
  const informationLinks = [
    { title: 'Getting Started', url: '/raw-feeding-101' },
    { title: 'About', url: '/about' },
    { title: 'Blog', url: '/blog' },
    { title: 'Raw feeding cheatsheet', url: '/cheatsheet' },
  ]

  const shopLinks = [
    { title: 'Buy Raw Dog Food', url: '/shop' },
    { title: "Poppy's Picnic", url: '/shop' },
    { title: 'Cart', url: '/basket' },
    { title: 'Contact', url: '/contact' },
  ]

  return (
    <div className="site-footer">
      <div className="container">
        <div className="site-footer__widgets">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <FooterContacts />
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <FooterLinks title="Raw Feeding" items={informationLinks} />
            </div>
            <div className="col-6 col-md-3 col-lg-2">
              <FooterLinks title="Shop" items={shopLinks} />
            </div>
            <div className="col-12 col-md-12 col-lg-4">
              <FooterNewsletter />
            </div>
          </div>
        </div>

        <div className="site-footer__bottom">
          <div className="site-footer__copyright">
            © {new Date().getFullYear()} Raw Dog Food
          </div>
          <div className="site-footer__payments">
            <LogoSmallSvg />
          </div>
        </div>
      </div>
    </div>
  )
}
