import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import StoreContext, { defaultStoreContext } from '../context/StoreContext'
import Header from '../components/Header/Header'

import MobileHeader from '../components/Mobile/MobileHeader'
import MobileMenu from '../components/Mobile/MobileMenu'
import Footer from '../components/Footer'
import '../scss/style.scss'

// export const ThemeContext = React.createContext(null);
// export const ScreenWidthContext = React.createContext(0);
// export const FontLoadedContext = React.createContext(false);

// import 'slick-carousel/slick/slick.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';

class Layout extends React.Component {
  state = {
    store: {
      ...defaultStoreContext,
      addVariantToCart: (variantId, quantity) => {
        if (variantId === '' || !quantity) {
          console.error('Both a size and quantity are required.')
          return
        }

        this.setState(state => ({
          store: {
            ...state.store,
            adding: true,
          },
        }))

        const { checkout, client } = this.state.store
        const checkoutId = checkout.id
        const lineItemsToUpdate = [
          { variantId, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .addLineItems(checkoutId, lineItemsToUpdate)
          .then(checkout => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout,
                adding: false,
              },
            }))
          })
      },
      removeLineItem: (client, checkoutID, lineItemID) => {
        return client.checkout
          .removeLineItems(checkoutID, [lineItemID])
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
      updateLineItem: (client, checkoutID, lineItemID, quantity) => {
        const lineItemsToUpdate = [
          { id: lineItemID, quantity: parseInt(quantity, 10) },
        ]

        return client.checkout
          .updateLineItems(checkoutID, lineItemsToUpdate)
          .then(res => {
            this.setState(state => ({
              store: {
                ...state.store,
                checkout: res,
              },
            }))
          })
      },
    },
  }

  async initializeCheckout() {
    // Check for an existing cart.
    const isBrowser = typeof window !== 'undefined'
    const existingCheckoutID = isBrowser
      ? localStorage.getItem('shopify_checkout_id')
      : null

    const setCheckoutInState = checkout => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', checkout.id)
      }

      this.setState(state => ({
        store: {
          ...state.store,
          checkout,
        },
      }))
    }

    const createNewCheckout = () => this.state.store.client.checkout.create()
    const fetchCheckout = id => this.state.store.client.checkout.fetch(id)

    if (existingCheckoutID) {
      try {
        const checkout = await fetchCheckout(existingCheckoutID)

        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          setCheckoutInState(checkout)
          return
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null)
      }
    }

    const newCheckout = await createNewCheckout()
    setCheckoutInState(newCheckout)
  }

  componentDidMount() {
    this.initializeCheckout()

    const preloader = document.querySelector('.site-preloader')
    if(preloader){
      setTimeout(() => {
        preloader.addEventListener('transitionend', event => {
          if (event.propertyName === 'opacity') {
            preloader.parentNode.removeChild(preloader)
          }
        })
        preloader.classList.add('site-preloader__fade')
      }, 500)
    }

  }

  render() {
    const { children } = this.props

    return (
      <StoreContext.Provider value={this.state.store}>
        <StaticQuery
          query={graphql`
            query SiteTitleQuery {
              site {
                siteMetadata {
                  title
                  author
                }
              }
            }
          `}
          render={data => (
            <React.Fragment>
              <MobileMenu />

              <div className="site">
                <header className="site__header d-lg-none">
                  <MobileHeader />
                </header>

                <header className="site__header d-lg-block d-none">
                  <Header siteTitle={data.site.siteMetadata.title} />
                </header>

                <div className="site__body">{children}</div>

                <footer className="site__footer">
                  <Footer />
                </footer>
              </div>
            </React.Fragment>
          )}
        />
      </StoreContext.Provider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
