// react
import React, { Component, useContext, useState, useEffect } from 'react'
import { connect } from 'react-redux'

// third-party
import classNames from 'classnames'
import { Link } from 'gatsby'

// application
import Indicator from '../Header/Indicator'
import Menu18x14Svg from '!svg-react-loader!../../images/svg/menu-18x14.svg'
import LogoSmallSvg from '!svg-react-loader!../../images/svg/RawDogFood-Badge.svg'
import Cross20Svg from '!svg-react-loader!../../images/svg/cross-20.svg'

// import { mobileMenuOpen } from './mobile-menu';

import CartIcon from '../Header/CartIcon'

// import StoreContext from '../../context/StoreContext'

function MobileHeader(props) {
  function openMobileMenu() {
    document.getElementById('mobileMenu').classList.add('mobilemenu--open')
  }

  return (
    <div className="mobile-header">
      <div className="mobile-header__panel">
        <div className="container">
          <div className="mobile-header__body">
            <Link to="/" className="mobile-header__logo">
              <LogoSmallSvg />
            </Link>
            <button
              type="button"
              className="mobile-header__menu-button"
              onClick={openMobileMenu}
            >
              <Menu18x14Svg />
            </button>

            <CartIcon name="mobile-header__indicators" />
          </div>
        </div>
      </div>
    </div>
  )
}

// const mapDispatchToProps = {
//     openMobileMenu: mobileMenuOpen,
// };

// export default connect(
//     mapDispatchToProps
// )(MobileHeader);
export default MobileHeader
