export default [
    {
        type: 'link',
        label: 'Home',
        url: '/'
    },
    {
        type: 'link',
        label: 'Shop',
        url: '/shop'
    },
    {
        type: 'link',
        label: 'Raw Feeding',
        url: '/shop',
        children: [
            { type: 'link', label: 'About', url: '/about' },
            { type: 'link', label: 'Blog', url: '/blog' },
            { type: 'link', label: 'Raw Feeding 101', url: '/raw-feeding-101' },
            { type: 'link', label: 'Cheatsheet', url: '/cheatsheet' }
        ],
    },
    {
        type: 'link',
        label: 'Contact',
        url: '/contact'
    },

];
