// react
import React from 'react'
import { Link } from 'gatsby'

export default function FooterContacts() {
  return (
    <div className="site-footer__widget footer-contacts">
      <h5 className="footer-contacts__title">Contact Us</h5>

      <div className="footer-contacts__text">
        <p>New to raw? Have a question?</p>
        <Link to="/contact" className="btn btn-primary">
          Contact us today
        </Link>
      </div>
    </div>
  )
}
