// react
import React from 'react'
import { connect } from 'react-redux'

// third-party
import classNames from 'classnames'

// application
import MobileLinks from './MobileLinks'
import Cross20Svg from '!svg-react-loader!../../images/svg/cross-20.svg'
import { mobileMenuClose } from './mobile-menu'

import mobileMenuLinks from '../../../content/meta/menuMobile'

// import StoreContext from '../../context/StoreContext'

function MobileMenu(props) {
  // const {
  //   // mobileMenuState,
  //   closeMobileMenu,
  // } = props

  // const classes = classNames('mobilemenu', {
  //     'mobilemenu--open': mobileMenuState.open,
  // });
  const classes = 'mobilemenu'

  const handleItemClick = item => {
    if (item.data) {
      closeMobileMenu()
    }
  }

  function closeMobileMenu() {
    document.getElementById('mobileMenu').classList.remove('mobilemenu--open')
  }

  return (
    <div id="mobileMenu" className={classes}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
      <div className="mobilemenu__body">
        <div className="mobilemenu__header">
          <div className="mobilemenu__title">Menu</div>
          <button
            type="button"
            className="mobilemenu__close"
            onClick={closeMobileMenu}
          >
            <Cross20Svg />
          </button>
        </div>
        <div className="mobilemenu__content">
          <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
        </div>
      </div>
    </div>
  )
}

// const mapStateToProps = state => ({
//     mobileMenuState: state.mobileMenu,
// });

// const mapDispatchToProps = {
//   closeMobileMenu: mobileMenuClose,
// }

// export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
export default MobileMenu
