// react
import React from 'react'
import { FaFacebookF } from 'react-icons/fa/'
import { FaInstagram } from 'react-icons/fa/'
import { FaTwitter } from 'react-icons/fa/'

export default function FooterNewsletter() {
  // const socialLinks = [
  //     {
  //         key: 'facebook',
  //         url: 'https://www.facebook.com/rawdogfoodco/',
  //         iconClass: 'fab fa-facebook-f',
  //     },
  //     {
  //         key: 'instagram',
  //         url: 'https://www.instagram.com/rawdogfoodco',
  //         iconClass: 'fab fa-instagram',
  //     },
  //     {
  //         key: 'twitter',
  //         url: 'https://twitter.com/rawdogfoodco',
  //         iconClass: 'fab fa-twitter',
  //     }
  // ];

  // const socialLinksList = socialLinks.map(item => (
  //     <li key={item.key} className={`footer-newsletter__social-link footer-newsletter__social-link--${item.key}`}>
  //         <a href={item.url} target="_blank" rel="noopener noreferrer">
  //             <i className={item.iconClass} />
  //         </a>
  //     </li>
  // ));

  return (
    <div className="site-footer__widget footer-newsletter">
      <h5 className="footer-newsletter__title">Social</h5>
      {/*<div className="footer-newsletter__text">
        Praesent pellentesque volutpat ex, vitae auctor lorem pulvinar mollis
        felis at lacinia.
  </div>*/}

      {/*<form action="" className="footer-newsletter__form">
        <label className="sr-only" htmlFor="footer-newsletter-address">
          Email Address
        </label>
        <input
          type="text"
          className="footer-newsletter__form-input form-control"
          id="footer-newsletter-address"
          placeholder="Email Address..."
        />
        <button
          type="submit"
          className="footer-newsletter__form-button btn btn-primary"
        >
          Subscribe
        </button>
  </form>*/}

      <div className="footer-newsletter__text footer-newsletter__text--social">
        Follow us online
      </div>

      <ul className="footer-newsletter__social-links">
        <li
          key="facebook"
          className="footer-newsletter__social-link footer-newsletter__social-link--facebook"
        >
          <a
            href="https://www.facebook.com/rawdogfoodco/"
            target=" _blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF />
          </a>
        </li>
        <li
          key="instagram"
          className="footer-newsletter__social-link footer-newsletter__social-link--instagram"
        >
          <a
            href="https://www.instagram.com/rawdogfoodco"
            target=" _blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li
          key="twitter"
          className="footer-newsletter__social-link footer-newsletter__social-link--twitter"
        >
          <a
            href="https://twitter.com/rawdogfoodco"
            target=" _blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
        </li>
      </ul>
    </div>
  )
}
