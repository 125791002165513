import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Cart20Svg from '!svg-react-loader!../../images/svg/cart-20.svg'

import StoreContext from '../../context/StoreContext'

const countQuantity = lineItems => {
  let quantity = 0

  lineItems.forEach(item => {
    quantity = quantity + item.quantity
  })

  return quantity
}

function CartIcon(props) {
  const context = useContext(StoreContext)
  const { checkout } = context
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  )

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []))
  }, [checkout])

  return (
    <div>
      {quantity !== 0 && (
        <div className={props.name}>
          <Link to="/basket" className="indicator__button">
            <Cart20Svg />
            <span className="indicator__value">{quantity}</span>
          </Link>
        </div>
      )}
    </div>
  )
}

export default CartIcon
